export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuth();

  let role = auth.user?.roles?.find((role) => role.name === "Owner");

  if (!role) {
    return navigateTo("/hub/sessions");
  } else {
    return;
  }
});
